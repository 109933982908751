import { default as indexsWom2NggGaMeta } from "/bok/pages/index.vue?macro=true";
export default [
  {
    name: indexsWom2NggGaMeta?.name ?? "index",
    path: indexsWom2NggGaMeta?.path ?? "/",
    meta: indexsWom2NggGaMeta || {},
    alias: indexsWom2NggGaMeta?.alias || [],
    redirect: indexsWom2NggGaMeta?.redirect || undefined,
    component: () => import("/bok/pages/index.vue").then(m => m.default || m)
  }
]